.App {
  min-height: 100vh;
  color: white;
  background-image: url("/public/bravo-gold-background.jpeg");
  background-attachment: fixed;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.flex-column {
  display: flex;
  flex-direction: column;
}

p, li {
    font-size: 20px;
    font-weight: 700;
}

li {
    margin-bottom: 10px;
}